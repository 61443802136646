<template>
    <div class="tw-w-full tw-h-full tw-bg-brand-area-color">
        <!-- Search Filters -->
        <search-bar v-model.trim="searchFilter" />

        <!-- querys List -->
        <neo-table :list="list" :headers="headers" :searchFilterKey="searchFilterKey" :searchFilter="searchFilter" :isLoading="loader" @checked="check" asyncPages :pageCount="pageCount" @update:page="changePage($event)" />
    </div>
</template>

<script>
import Table from "@/components/table-expandable";
import {mapGetters, mapActions} from "vuex";
import {EventBus} from "@/main.js";
import SearchBar from "@/components/searchbar";

export default {
    name: "search-engine-queries",
    components: {
        "neo-table": Table,
        SearchBar,
    },
    props: [],
    data() {
        return {
            list: [],
            searchFilterKey: "category_name",
            headers: [
                // {
                //     text: "Priority",
                //     value: "priority",
                //     class: 'tw-w-1/4',
                // },
                {
                    value: "checked",
                    class: "toggle-cell",
                    type: "toggle",
                },
                {
                    text: "Category",
                    value: "category_name",
                    class: "tw-w-1/6 tw-flex-grow",
                },
                // {
                //     text: "Searched word",
                //     value: "searched_word",
                //     class: 'tw-w-2/5',
                // },
                // {
                //     text: "Status",
                //     value: "status",
                //     class: 'tw-w-1/5',
                // },
                // {
                //     text: "Description",
                //     value: "description",
                //     class: 'tw-w-2/5',
                // }
            ],
            pageCount: 0,
            searchFilter: "",
            loader: false,
        };
    },
    async mounted() {
        this.loader = true;
        await this.initPage();
        this.loader = false;
    },
    computed: {
        ...mapGetters(["getProduct", "getAccountId", "getCompanyProducts"]),
        product() {
            return this.getProduct.key ? this.getProduct : this.getCompanyProducts.find((el) => el.name === this.$route.query.product);
        },
        API() {
            return {
                get: async (payload) => await this.getProductSearchEngineCategories({product_id: this.product.key, params: payload}),
                post: this.postProductSearchEngineCategories,
            };
        },
    },
    methods: {
        ...mapActions(["getProductSearchEngineCategories", "postProductSearchEngineCategories"]),
        async initPage({page = 1} = {}) {
            this.loader = true;
            try {
                const response = await this.API.get({
                    current_page: page,
                    // company_id: this.getAccountId, // ? uncomment?
                });
                this.list = response.list.map((el, index) => ({
                    ...el,
                    id: el._id,
                    [this.sublistProp]: [],
                    order: index,
                }));
                this.pageCount = response.pagination.total_pages;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            this.loader = false;
        },
        async check({item, value}) {
            const id = item.id;
            try {
                EventBus.$emit("topprogress", "start");
                const elToChange = this.list.find((el, index) => el.id === id);
                await this.API.post({
                    product_id: this.product.key,
                    id: value ? id : elToChange.category_id,
                    value,
                });
                elToChange.checked = value;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
        async changePage(page) {
            await this.initPage({page});
        },
    },
};
</script>

<style></style>
